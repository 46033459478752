<template>
  <div class="relative h-screen items-center max-md:px-5 md:flex">
    <div class="flex h-full w-3/5 flex-none shrink-0 flex-col items-center justify-center max-md:w-full md:h-full md:overflow-y-auto lg:w-2/5">
      <img
        src="~/assets/images/logo-dark.svg"
        class="mx-auto mt-10 w-full max-w-48 md:hidden"
        alt=""
      >
      <div class="mx-auto size-full max-w-sm px-4 lg:px-6">
        <slot />
      </div>
    </div>
    <div class="relative hidden h-screen w-2/5 items-center justify-center bg-primary px-4 md:flex lg:w-3/5 lg:px-6">
      <img
        src="~/assets/images/logo.svg"
        class="w-full max-w-sm -translate-y-full"
        alt=""
      >

      <img
        src="~/assets/images/road.svg"
        class="absolute inset-x-0 bottom-0 object-cover"
        alt=""
      >
    </div>
  </div>
</template>
